<template>
  <div class="transaction-wrapper">
    <div class="grid_menu">
      <button @click="currentPanel = 0" class="bordered btn" :class="{ active: !currentPanel }">
        Patient
      </button>
      <button @click="currentPanel = 1" class="bordered btn" :class="{ active: currentPanel }">
        Doctor
      </button>
    </div>
    <div class="grid_container">
      <div class="grid p-4 bg-white rounded">
        <dx-grid-with-search
          ref="historyGrid"
          title="Transactions"
          gridName="caseHistory"
          v-stream:content-ready="historyGrid$"
          :columns="historyGrid"
          :dataSource="patientHistory$"
          :noDataText="noDataText"
          :selection="selection"
          :pageSize="8"
          @selection-changed="setSelected"
          :selectedRowKeys="selectedRow"
        />
        <div class="row mt-2 border-top pt-3" v-if="selectedCase">
          <h5 v-if="hasSpecimen">{{ selectedCase && selectedCase.caseNumber }} Transactions</h5>
          <div
            v-if="hasSpecimen && permissions.CaseTransactionCreateEdit"
            class="d-flex ml-auto mb-2"
          >
            <add-button class="ml-auto mx-2" @click="addNewTx" />
            <icon-button
              v-stream:click="deleteBtn$"
              :disabled="!Boolean(selectedTx.length)"
              icon="trash-alt"
              class="btn btn-outline-danger"
            >
              <span class="ml-1"> Delete </span>
            </icon-button>
          </div>

          <span v-else-if="!hasSpecimen" class="text-muted m-auto"
            >Transactions is unavailable, this accession has no specimens</span
          >
          <PropTable
            ref="transactionGrid"
            v-if="hasSpecimen"
            class="w-100"
            :canDelete="permissions.CaseTransactionDelete"
            :canEdit="permissions.CaseTransactionCreateEdit"
            v-model="selectedCaseTransactions"
            :columns="txGridColumns"
            :gridSummary="txSummary"
            :show-borders="true"
            :apiRemove="true"
            @remove="deleteTx"
            @edit="handleEditTx"
            :noDataText="'This accession has no transactions.'"
            :selection="txSelection"
            @selectedRowKeys="handleSelection"
          />
        </div>
      </div>
    </div>

    <Modal :status="isModalOpen" @close="toggleModal">
      <Transaction
        :caseId="selectedCase"
        :txId="currentTransaction"
        @close="toggleModal"
        @submit="handleTxSubmit"
        :currentTab="currentPanel"
        :autoCheckSend="autoCheckSend"
      />
    </Modal>
  </div>
</template>

<script>
import PropTable from "@/components/common/PropTable.vue";
import Transaction from "@/components/forms/Accession/Transaction.vue";
import Modal from "@/components/common/Modal";
import { mapState, mapGetters } from "vuex";
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import CasesApi from "@/services/cases";
import { SpecimensApi, TransactionsApi } from "../services";
import {
  catchError,
  concatMap,
  filter,
  last,
  map,
  mergeMap,
  startWith,
  switchMap,
  tap
} from "rxjs/operators";
import { combineLatest, from } from "rxjs";
import { filterAccessionNumber, filterCellUTC, formatDatetimeCell } from "../modules/helpers";
import AddButton from "./common/AddButton.vue";
import IconButton from "./common/IconButton.vue";

export default {
  name: "Accession-Transaction-Panel",
  components: { Modal, Transaction, PropTable, DxGridWithSearch, AddButton, IconButton },
  computed: {
    ...mapState({
      currentUser: state => state.currentUser,
      caseDetails: state => state.accessionStore.caseDetails
    }),
    ...mapGetters(["permissions"]),

    historyDataGrid() {
      return this.$refs?.historyGrid?.grid;
    },
    caseId() {
      if (this.caseDetails) {
        return this.caseDetails.caseId;
      }
      return null;
    },
    hasSpecimen() {
      return Boolean(this.specimen$?.length);
    },
    noDataText() {
      return `No accessions found with the billing type of ${
        this.currentPanel ? "doctor." : "patient."
      }`;
    },
    txFixedFilters() {
      return [
        {
          operator: "=",
          value: this.caseId,
          column: "caseId"
        }
      ];
    },
    txSummary() {
      return {
        totalItems: [
          {
            summaryType: "sum",
            column: "amount",
            displayFormat: "Total:  {0} ",
            valueFormat: "currency",
            showInColumn: "amount"
          }
        ]
      };
    }
  },
  created() {
    const { caseId } = this.$route.params;
    this.$store.dispatch("accessionStore/getTransactions", caseId);
  },
  mounted() {
    this.setSelected({ selectedRowKeys: [this.caseDetails.caseId] });
    this.currentPanel = this.caseDetails.billingType ?? 0;
  },
  domStreams: ["historyGrid$", "txGridComponent$", "deleteBtn$"],
  subscriptions() {
    const { caseId } = this.$route.params;
    const currentPanel$ = this.$watchAsObservable("currentPanel", { immediate: true }).pipe(
      map(({ newValue }) => newValue)
    );
    const patientHistory$ = currentPanel$.pipe(
      map(billingTypePanel => {
        const loadOptions = options => {
          if (Array.isArray(options.filter)) {
            options.filter = [options.filter, "and", ["billingTypeId", billingTypePanel ?? 0]];
          } else {
            options.filter = ["billingTypeId", billingTypePanel ?? 0];
          }
        };
        return CasesApi.getPatientHistoryStore(caseId, loadOptions);
      })
    );

    const historyGrid$ = this.historyGrid$.pipe(map(data => data.event.msg.component));
    const selectedCase$ = this.$watchAsObservable("selectedCase", { immediate: true }).pipe(
      map(({ newValue }) => newValue)
    );

    const specimen$ = selectedCase$.pipe(
      startWith(caseId),
      filter(d => d),
      switchMap(async caseId => {
        if (caseId === this.caseDetails.caseId) {
          return this.$store.state.accessionStore.specimens;
        }

        return SpecimensApi.getSpecimen(caseId);
      }),
      catchError((e, s) => s)
    );
    const selectedCaseDetails$ = selectedCase$.pipe(
      startWith(caseId),
      filter(d => d),
      switchMap(async caseId => {
        if (caseId === this.caseDetails.caseId) {
          return this.caseDetails;
        }
        return CasesApi.getCaseById(caseId);
      }),
      catchError((e, s) => s)
    );
    const scrollToSelectedCase$ = combineLatest([historyGrid$, currentPanel$]).pipe(
      tap(data => {
        const [historyGrid] = data;
        historyGrid.navigateToRow(this.selectedCase);
      })
    );
    const deleteSelectedTx$ = this.deleteBtn$.pipe(
      switchMap(() => {
        const anySent = this.selectedTx.find(e => e.sentOn);
        return window.confirm(
          `WARNING: This action is irreversible.<br>${
            anySent ? "At least one transaction has been sent. <br>" : ""
          } You have ${
            this.selectedTx.length
          } selected transactions. <br> Are you sure you want to delete these?`
        );
      }),
      filter(confirmed => confirmed),
      mergeMap(() => {
        return from(this.selectedTx).pipe(
          concatMap(tx => this.$store.dispatch("accessionStore/removeCaseTx", tx)),
          last()
        );
      }),
      tap(() => {
        this.fetchTransactions(this.selectedCase);
      }),
      catchError((e, s) => {
        return s;
      })
    );
    return {
      deleteSelectedTx$,
      patientHistory$,
      scrollToSelectedCase$,
      specimen$,
      selectedCaseDetails$
    };
  },
  data() {
    return {
      selection: {
        mode: "single",
        deferred: false,
        showCheckBoxesMode: "always"
      },
      txSelection: {
        mode: "multiple",
        deferred: false,
        showCheckBoxesMode: "always"
      },
      currentPanel: 0,
      patientId: null,
      caseNumber: "",
      selectedCase: null,
      selectedTx: [],
      isModalOpen: false,
      selectedRow: [],
      currentTransaction: null,
      selectedCaseTransactions: [],
      historyGrid: [
        {
          dataField: "caseNumber",
          filterField: "caseNumber",
          caption: "Case #",
          calculateFilterExpression: filterAccessionNumber(
            "caseNumber",
            this.$store.state.labSettings.AccessionNumberingType
          )
        },
        {
          dataField: "receivedOn",
          caption: "Received",
          sortOrder: "desc",
          dataType: "date"
        },
        {
          dataField: "charges",
          format: "currency",
          dataType: "number"
        },
        {
          dataField: "provider"
        },
        {
          dataField: "billingCycle",
          caption: "Cycle"
        },
        {
          dataField: "location"
        }
      ],
      txGridColumns: [
        {
          dataField: "billingTypeId",
          caption: "P/D",
          width: "5%",
          calculateCellValue({ billingTypeId }) {
            return billingTypeId === 1 ? "D" : "P";
          }
        },
        {
          dataField: "code",
          width: "7%",
          allowResizing: true
        },
        {
          dataField: "description",
          width: "15%",
          allowResizing: true,
          dataType: "string"
        },
        {
          width: "12%",
          dataField: "qty",
          caption: "Quantity",
          dataType: "number"
        },

        {
          dataField: "amount",
          format: "currency",
          width: "12%",
          allowResizing: true
        },
        {
          dataField: "occurredOn",
          caption: "Trans Date",
          dataType: "date",
          width: "10%",
          allowResizing: true
        },
        {
          dataField: "recordedOn",
          caption: "Posted",
          dataType: "datetime",
          width: "12%",
          allowResizing: true,
          calculateCellValue(data) {
            if (data.recordedOn) {
              return formatDatetimeCell(data.recordedOn);
            }
            return data.recordedOn;
          },
          calculateFilterExpression: filterCellUTC("recordedOn")
        },
        {
          dataField: "postedBy",
          caption: "User",
          dataType: "string",
          width: "15%",
          allowResizing: true
        },
        {
          dataField: "rateNum",
          caption: "Rate",
          dataType: "string",
          width: "7%",
          allowResizing: true
        }
      ],
      autoCheckSend: false
    };
  },
  watch: {
    selectedRow: {
      immediate: true,
      deep: true,
      handler(nv) {
        nv[0] && this.fetchTransactions(nv[0]);
      }
    }
  },
  methods: {
    handleSelection(data) {
      this.selectedTx = data;
    },
    async fetchTransactions(caseId) {
      this.selectedCaseTransactions = await TransactionsApi.transactionsStore.load({
        filter: caseId && ["caseId", "=", caseId]
      });
      if (!this.selectedCaseTransactions.length && this.hasSpecimen) {
        this.addNewTx();
      }
      return this.selectedCaseTransactions;
    },
    toggleModal() {
      this.currentTransaction = null;
      this.isEditing = !this.isEditing;
      this.isAdding = !this.isAdding;
      this.autoCheckSend = false;
      return (this.isModalOpen = !this.isModalOpen);
    },
    addNewTx() {
      this.isModalOpen = true;
      this.currentTransaction = null;
      this.autoCheckSend = true;
    },
    async deleteTx({ data }) {
      const tx = data;
      const confirmation = await window.confirm(
        `WARNING: This action is irreversible.<br> Are you sure you want to delete this transaction with the amount of $${tx.amount}?`
      );
      if (confirmation) {
        return this.$store
          .dispatch("accessionStore/removeCaseTx", tx)
          .then(() => this.fetchTransactions(this.selectedCase));
      }
    },
    setSelected({ selectedRowKeys }) {
      this.selectedCase = selectedRowKeys[0];
      this.selectedRow = selectedRowKeys;
    },
    handleEditTx({ data }) {
      this.currentTransaction = data.id;
      this.isModalOpen = true;
    },
    async handleTxSubmit() {
      await this.fetchTransactions(this.selectedCase);
      this.currentTransaction = null;
      this.isModalOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.grid_menu {
  background-color: $secondary;
  & > button {
    font-size: 1.2rem;
    font-weight: 500;
    background-color: $primary-light;
    margin-bottom: -1px;
    border-bottom: none;
    margin-left: -1px;
    &.active {
      background-color: $white;

      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.transaction-wrapper {
  padding: 0 !important;
}

.grid_container {
  /* background-color: $primary-light; */
  width: 100%;
}
.main_grid {
  flex: 1;
  /* background-color: #047ead !important; */
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0 !important;
  flex-direction: column;
}
.disabled {
  opacity: 0.7;
}
</style>
