<template>
  <div class="text-input">
    <label v-if="!noLabel && label" :for="label">
      <b>{{ label }}</b>
    </label>
    <div class="input-group">
      <input
        v-model="displayValue"
        @blur="isInputActive = false"
        @focus="isInputActive = true"
        @mousewheel.prevent
        class="form-control"
        :class="{
          'is-invalid':
            (validator && validator.$model && validator.$invalid) ||
            (validator && validator.$error),
          'is-valid': validator && validator.$model && !validator.$invalid,
          'has-error': validator && validator.$model && validator && validator.$error
        }"
        ref="input"
        type="text"
        v-bind="$attrs"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        :style="customStyles"
        :aria-label="label"
        @keydown.69.prevent
      />
    </div>
    <div v-if="(validator && validator.$error) || (validator && validator.$invalid)">
      <div
        class="validation-error"
        v-for="(key, index) in Object.keys(validator.$params)"
        :key="index"
      >
        <span class="error" v-if="!validator[key]">
          {{ validatorMsgMap[key] }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { validatorMsgMapBase } from "@/modules/helpers";
export default {
  name: "CurrencyInput",
  inheritAttrs: false,
  props: {
    noLabel: {
      type: Boolean
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      required: false,
      default() {
        return 0;
      }
    },
    type: {
      type: String,
      default: "text"
    },
    customStyles: {
      type: Object
    },
    validatorMsgMap: {
      type: Object,
      default: () => {
        return validatorMsgMapBase;
      }
    },
    validator: {
      type: Object,
      default: null
    },
    required: {
      type: Boolean
    }
  },
  data() {
    return { isInputActive: false };
  },
  created() {
    if (this.value) {
      if (isNaN(this.value)) {
        this.$emit("input", Number(this.value));
      }
    } else {
      this.$emit("input", 0);
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    }
  },
  computed: {
    displayValue: {
      get: function () {
        if (this.isInputActive) {
          // Cursor is inside the input field. unformat display value for user
          return this.value.toString();
        } else {
          // User is not modifying now. Format display value for user interface
          return (
            "$ " +
            Number(this.value)
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
          );
        }
      },
      set: function (modifiedValue) {
        // Recalculate value after ignoring "$" and "," in user input
        let newValue = parseFloat(modifiedValue.replace(/[^\d.]/g, ""));
        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = 0;
        }
        // Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
        // $emit the event so that parent component gets it
        this.$emit("input", newValue);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.error {
  font-size: 0.75rem;
}
.hide_arrows {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
